<template>
    <div class="ipad-page display-case-list">
        <div class="inner">
            <div class="display-section">
                <div class="toolbar">
                    <div class="title">
                        <div>請於下方選擇欲篩選的案例類型</div>
                    </div>
                    <div class="search">
                        <el-input
                            v-model="searchKeyword"
                            placeholder="搜尋關鍵字"
                            clearable
                            :suffix-icon="
                                searchKeyword ? undefined : 'el-icon-search'
                            "
                            @change="onTextFilterChange"
                        ></el-input>
                    </div>
                </div>
                <div class="toolbar">
                    <div class="title w-full">
                        <div>
                            <FilterTool
                                :rows="config"
                                :filter="filter"
                                @change="onFilterChange"
                                @clear="onFilterClear"
                            />
                        </div>
                    </div>
                </div>
                <SalesCase :data="list" :on-update="handleInfinite">
                </SalesCase>
            </div>
        </div>
        <div class="policy">
            <a
                href="https://www.roche.com.tw/zh/privacy-policy.html"
                class="privacy"
                >隱私聲明</a
            >
            <div class="AE" @click="onOpenAEPopup">AE通報資訊</div>
            <div
                class="reveal-information"
                @click="onOpenRevealInformationPopup"
            >
                羅氏揭露訊息
            </div>
        </div>
    </div>
</template>

<script>
import FilterTool from '@/components/CaseFilter/FilterTool.vue';
import SalesCase from '@/components/Table/SalesCase.vue';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
    components: { SalesCase, FilterTool },
    data() {
        return {
            loading: false,
            searchKeyword: '',

            value: undefined,
            filter: undefined,
        };
    },
    computed: {
        ...mapState({
            list: (state) => state.display.caseList,
            filterState: (state) => state.display.filterState.value,
            config: (state) => state.config.filterTag,
        }),
    },
    created() {
        this.fetchFilter(this.$route.query);
    },
    destroyed() {
        this.clear();
    },
    methods: {
        ...mapMutations({
            updateGenerateLinkAndPasswordPopUpState:
                'popup/updateGenerateLinkAndPasswordPopUpState',
            updateAEDisclaimerPopUpState: 'popup/updateAEDisclaimerPopUpState',
            updateRevealInformationPopupState: 'popup/updateRevealInformationPopupState',

            updateConfirmationState: 'popup/updateConfirmationState',
            updateConfirmationText: 'popup/updateConfirmationText',
            updateConfirmationFunction: 'popup/updateConfirmationFunction',

            setMode: 'form/setMode',

            updateFilterText: 'display/updateFilterText',
            updateFilterData: 'display/updateFilterData',
            increasePage: 'display/increasePage',
            clear: 'display/clear',
            setTarget: 'display/setTarget',
        }),
        ...mapActions({
            apiClient: 'api/invoke',

            fetchQuery: 'display/fetchQuery',
            updateFilter: 'display/updateFilter',
            onSearch: 'display/onSearchDisplayCase',
        }),
        async handleInfinite($state) {
            try {
                this.loading = true;
                this.fetchQuery();
                const RES = await this.onSearch();
                this.increasePage();
                if (RES) $state.loaded();
                else $state.complete();
            } catch (e) {
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        fetchFilter(filter) {
            this.filter = filter;
        },
        onFilterClear(filter) {
            this.updateFilterData(filter);
            this.updateFilter();
        },
        onFilterChange(filter) {
            if (!filter) return;
            this.updateFilterData(filter);
            this.updateFilter();
        },
        onTextFilterChange(text) {
            this.updateFilterText(text);
            this.updateFilter();
        },
        onOpenAEPopup() {
            this.updateAEDisclaimerPopUpState(true);
        },
        onOpenRevealInformationPopup() {
            this.updateRevealInformationPopupState(true);
        },
    },
};
</script>

<style lang="scss" scoped>
.display-case-list {
    position: relative;
    .title {
        font-size: 20px;
    }
    .policy {
        position: absolute;
        bottom: 0.5%;
        left: 3%;
        display: flex;
        font-size: 12px;
        text-decoration: underline;
        a {
            color: #000;
        }
        > :first-child {
            margin-right: 10px;
        }
        > * {
            cursor: pointer;
        }

        .reveal-information {
            margin-left: 10px;
        }
    }
}
</style>
